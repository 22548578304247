
import { defineComponent } from "vue";
import SettlementEntityInfomation from "@/components/page/kyc/settlement-entity/Information.vue";

export default defineComponent({
  name: "settlement-entity-natural-person-information",
  components: {
    SettlementEntityInfomation,
  },
  setup() {
    return {};
  },
});
